<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                PC Members
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header d-flex align-items-center justify-content-between"
              >
                <h3>Add new PC Member</h3>
              </div>
              <div class="row p-1 pt-0">
                <div class="col-sm-10">
                  <v-select
                    v-model="pcmember"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    :placeholder="'Search and add a new PC Member'"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    class="btn btn-dark w-100"
                    @click="savePcMember"
                  >
                    Add
                  </button> <!-- modal o toast que informe que se ha añadido -->
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body p-0">
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="members"
                  :sort-mapping="sortMapping"
                  :title="'PC Members'"
                  :total="membersTotal"
                  :fetch-path="'pcMembers/filter'"
                >
                  <template v-slot="{field}">
                    <td
                      class="text-end"
                    >
                      <span
                        class="dis-ib"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete area & group"
                        @click="showDeleteModal(field.id)"
                      ><a
                        href="#"
                        class="btn btn-icon rounded-circle btn-flat-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#modalDeleteWithInput"
                      ><i data-feather="trash-2" /></a></span>
                    </td>
                  </template>
                </CompleteTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'ERC',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas',
        ERC: 'erc.code',
        Institution: 'hosts_table',
      },
      pcmember: null,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      members: 'pcMembers/items',
      membersTotal: 'pcMembers/itemsTotal',
      users: 'users/fakeUsers',
      filtersAux: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('pcMembers/filter', this.filtersAux)
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
    await this.$store.dispatch('modals/fetchUserFields', 'pc_members_list')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'pc_members_list',
      })
    }
  },
  methods: {
    async showDeleteModal(id) {
      // Remove PCMember role of the user
      await this.$store.dispatch('modals/saveDeleteData', { name: 'pcMembers', url: `${Vue.prototype.$groupUrl}/pc-members/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async savePcMember() {
      if (this.pcmember.id) {
        await this.$store.dispatch('pcMembers/update', { id: this.pcmember.id, data: this.pcmember })
        Vue.swal('PC Member added successfully!', '', 'success')
        await this.$store.dispatch('pcMembers/filter', this.filtersAux)
        this.pcmember = null
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)
      }
    },
  },
}
</script>
